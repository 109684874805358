// import Card from 'common/components/Card/Card';
import { useCallback, useMemo, useState, useEffect } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { Transition } from "@headlessui/react";

import { getDefaultPostTypeProps } from "conf/pubTypesData";

import Status from "common/components/Status/Status";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import EditHeader from "common/components/EditHeader/EditHeader";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import RadioBlockItem from "common/components/RadioBlockItem/RadioBlockItem";
import CheckBoxBlockItem from "common/components/CheckBoxBlockItem/CheckBoxBlockItem";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useList from "common/hooks/useList";
import useRouter from "common/hooks/use-router";

import { Site } from "types";
import { ContactGroup } from "types/ContactGroup";
import { useTranslation } from "react-i18next";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import dayjs from "dayjs";
import { FieldArray } from "react-final-form-arrays";
import SortableList from "common/components/SortableList/SortableList";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import EmptyResult from "common/components/EmptyResult/EmptyResult";
import CommentsList from "common/components/CommentsList/CommentsList";
import getWording from "common/utils/wording";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import enableMapField from "common/utils/enableMapField";
import QAndAItemForm from "common/components/QAndA/QAndAItemForm";
import { AlertType } from "types/AlertType";
import { PostType } from "types/PostType";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import imgSizes from "conf/imgSizes.json";
import Button from "common/components/Button/Button";
import checkRights from "../../common/utils/checkRights";

const transitionAnimationProps = {
  enter: "transition-opacity ease-linear duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity ease-linear duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};

const getContactGroupsValue = (post?: any, isPublicByDefault = false) => {
  const groups =
    post?.contactGroups?.map((group: ContactGroup) => group.id) || [];
  if (post?.isPublic || isPublicByDefault) {
    groups.unshift("public");
  }
  return groups;
};

function PlannedNotificationItem({ index, name, fields }: any) {
  const { t } = useTranslation();

  const durationUnities = [
    { value: "minutes", label: t("common.minutes") },
    { value: "hours", label: t("common.hours") },
    { value: "days", label: t("common.days") },
  ];

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="responsive-flex w-full gap-3 items-center">
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            name={`${name}.durationNumber`}
            titleKey={t("forms.duration")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="flex md:flex-1 items-start text-xs gap-2">
          <FormControl
            type="select"
            title={t("forms.durationType")}
            name={`${name}.durationType`}
            options={durationUnities}
            containerStyle="flex-1"
            required
          />
        </div>
      </div>

      <div className="pr-4">
        <Button
          type="error-line"
          onClick={() => fields.remove(index)}
          icon="TrashIcon"
          className="px-3 py-3"
          compact
        />
      </div>
    </div>
  );
}

function PostForm({
  onSubmit,
  // onSubmitKeepPage,
  post,
  fetchPost,
  updatePost,
  isUpdateFetching,
}: any) {
  const [siteId, setSiteId] = useState(post?.site?.id);
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const isDuplicated = router.pathname.includes("duplicate");

  const alertLevelList = [
    { value: "low", label: t("forms.low") },
    { value: "medium", label: t("forms.medium") },
    { value: "high", label: t("forms.high") },
  ];

  const { items: alertTypes } = useList<AlertType>("alert-types", {
    defaultSort: "name:ASC",
    populate: ["icon"],
  });

  const { items: postTypes } = useList<PostType>("post-types", {
    defaultSort: "name:ASC",
    populate: ["imageLightMode", "imageDarkMode"],
  });

  const alertTypeList = useMemo(() => {
    return alertTypes?.map((type: AlertType) => ({
      value: type.id,
      label: type.name,
    }));
  }, [alertTypes]);

  const { items: sites, setFilter: setFilterSite } = useList<Site>("sites", {
    populate: ["contactGroups", "siteType"],
    defaultFilters: currentOrganization?.id
      ? {
          organization: currentOrganization.id,
          active: true,
          disabled: false,
        }
      : {
          active: true,
          disabled: false,
        },
    defaultSorts: ["siteType.id:ASC", "name:ASC"],
    cachePrefix: `PostForm_${currentOrganization?.id || "all"}`,
    defaultItemsPerPage: 5000,
  });

  useEffect(() => {
    setFilterSite("organization", currentOrganization?.id || false);
  }, [currentOrganization, setFilterSite]);

  const back = useCallback(() => {
    if (!post?.id) {
      router.navigate("/posts");
      return;
    }
    router.navigate(`/posts/${post.id}`);
  }, [router, post]);

  const currentSite = (sites || []).find((s: Site) => s.id === siteId);

  const onSiteChange = (value: any, form: any) => {
    const newSite = (sites || []).find((s: Site) => s.id === value);

    setSiteId(value);

    form.mutators.setFieldValue({
      field: "geojson",
      value: newSite?.siteType?.isGeo
        ? {
            lat: newSite?.geojson?.lat,
            lng: newSite?.geojson?.lng,
          }
        : null,
    });
  };

  const onContactChange = (value: any, form: any) => {
    form.mutators.setFieldValue({
      field: "isPublic",
      value: value.includes("public"),
    });
  };

  const contactGroupsList = useMemo(() => {
    const publicGroup = {
      id: "public",
      name: t("common.public"),
      isSpecial: true,
    };

    const organizationContactGroups =
      currentOrganization?.allowedGroups?.filter(
        (organizationContactGroup: any) =>
          organizationContactGroup.appliedToTheWholeOrganization
      ) || [];

    let contactList = [];

    if (!siteId) {
      contactList = [publicGroup, ...organizationContactGroups];
    } else {
      const currentGroups = (sites || []).find(
        (item: Site) => item.id === siteId
      );

      if (!currentGroups || !currentGroups.contactGroups.length) {
        contactList = [publicGroup, ...organizationContactGroups];
      } else {
        contactList = [
          publicGroup,
          ...organizationContactGroups,
          ...currentGroups.contactGroups,
        ];
      }
    }

    return contactList.map((item: any) => ({
      id: item.id,
      value: item.id,
      title: item.name,
      isSpecial: item.isSpecial,
    }));
  }, [t, currentOrganization?.allowedGroups, siteId, sites]);

  const getRoundDate = (currentDate: any) => {
    const arrangedHours = Math.round(currentDate.getMinutes() / 15);
    if (arrangedHours === 4) {
      currentDate.setHours(currentDate.getHours() + 1);
    }
    let rounded = (arrangedHours * 15) % 60;
    if (rounded > currentDate.getMinutes()) {
      rounded -= 15;
    }
    currentDate.setMinutes(rounded);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    return currentDate;
  };

  const initialValues = useMemo(
    () =>
      post?.id
        ? isDuplicated
          ? {
              ...post,
              notificationSent: false,
              threads: null,
              status: null,
              privateThread: null,
              upVotes: null,
              thanks: null,
              pinned: false,
              pinnedDate: null,
              contentRich: post.contentRich || post.content,
              adviceRich: post.adviceRich || post.advice,
              actionRich: post.actionRich || post.action,
              type: post.type?.id,
              alertType: post.alertType?.id,
              site: post.site?.id,
              contactGroups: getContactGroupsValue(post),
              documents: post.documents ? post.documents : [],
              geojson: {
                lat: post?.geojson?.lat,
                lng: post?.geojson?.lng,
              },
              geojsonTemp: {
                lat: post?.geojson?.lat ?? 43.6043,
                lng: post?.geojson?.lng ?? 1.4437,
              },
            }
          : {
              ...post,
              contentRich: post.contentRich || post.content,
              adviceRich: post.adviceRich || post.advice,
              actionRich: post.actionRich || post.action,
              type: post.type?.id,
              alertType: post.alertType?.id,
              site: post.site?.id,
              contactGroups: getContactGroupsValue(post),
              // qAndAs: post.qAndAs?.map((q: QAndA) => q.id), todo : back anthonin
              documents: post.documents ? post.documents : [],
              geojson: {
                lat: post?.geojson?.lat,
                lng: post?.geojson?.lng,
              },
              geojsonTemp: {
                lat: post?.geojson?.lat ?? 43.6043,
                lng: post?.geojson?.lng ?? 1.4437,
              },
            }
        : {
            type: 3,
            status: null,
            title: null,
            site: null,
            description: null,
            publishedDate: getRoundDate(new Date()),
            endPublishedDate: null,
            startEventDate: null,
            endEventDate: null,
            location: null,
            alertLevel: null,
            alertType: null,
            action: null,
            advice: null,
            notifyBySMS: false,
            smsContent: null,
            qAndAs: [],
            geojson: {
              lat: 43.6043,
              lng: 1.4437,
            },
            geojsonTemp: {
              lat: 43.6043,
              lng: 1.4437,
            },
            isPublic: currentOrganization?.isPublicByDefault,
            contactGroups: getContactGroupsValue(
              null,
              currentOrganization?.isPublicByDefault
            ),
            plannedNotifications: [],
          },
    [currentOrganization?.isPublicByDefault, post, isDuplicated]
  );

  const canManagePost = checkRights(
    currentUser,
    "canManagePost",
    post ? [post?.site?.id] : undefined,
    ["isAdmin"]
  );

  const canComment = checkRights(
    currentUser,
    "canComment",
    post ? [post?.site.id] : undefined,
    ["isAdmin"]
  );

  const canEditPublishedDate = useMemo(() => {
    return post?.status === "online";
  }, [post]);

  const refresh = useCallback(() => {
    fetchPost();
  }, [fetchPost]);

  const onSubmitWrapper = async (values: any) => {
    const data = {
      ...values,
      plannedNotifications:
        values.startEventDate &&
        values.plannedNotifications &&
        values.plannedNotifications.length > 0
          ? values.plannedNotifications?.map((notif: any) => {
              return {
                durationType: notif.durationType,
                notificationSent: false,
                durationNumber: Number(notif.durationNumber) ?? 1,
                notificationDate: dayjs(values.startEventDate)
                  .subtract(
                    Number(notif.durationNumber) ?? 1,
                    notif.durationType
                  )
                  .toDate(),
              };
            })
          : [],
    };
    const res = await onSubmit(data);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  const onUpdateStatus = useCallback(
    async (newStatus: string) => {
      try {
        await updatePost(post?.id, { status: newStatus }, true, "updateStatus");
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [updatePost, post]
  );

  const onUnpublish = useCallback(() => {
    onUpdateStatus("pending_moderation");
  }, [onUpdateStatus]);

  const onExpirate = useCallback(() => {
    onUpdateStatus("offline");
  }, [onUpdateStatus]);

  const onUnexpirate = useCallback(() => {
    onUpdateStatus("online");
  }, [onUpdateStatus]);

  const onValidate = useCallback(() => {
    if (post?.publishedDate && dayjs().isBefore(post?.publishedDate)) {
      onUpdateStatus("pending_publication");
      return null;
    }

    if (post?.endPublishedDate && dayjs().isAfter(post?.endPublishedDate)) {
      onUpdateStatus("offline");
      return null;
    }
    onUpdateStatus("online");
    return null;
  }, [post, onUpdateStatus]);

  const formatedPostTypes = useMemo(() => {
    return postTypes
      ?.map((pT) => {
        return {
          ...pT,
          title: pT.name,
          color: pT.color || getDefaultPostTypeProps(pT.value)?.color,
          imageLightMode: pT.imageLightMode
            ? ImageFromStrapiMedia(pT.imageLightMode)?.uri
            : undefined,
          imageDarkMode: pT.imageDarkMode
            ? ImageFromStrapiMedia(pT.imageDarkMode)?.uri
            : undefined,
          icon:
            pT.imageLightMode && pT.imageDarkMode
              ? undefined
              : pT.icon || getDefaultPostTypeProps(pT.value)?.icon,
          indexInList: pT.indexInList ?? 0,
        };
      })
      .sort((a, b) => {
        return (a.indexInList ?? 0) - (b.indexInList ?? 0);
      });
  }, [postTypes]);

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "geojson.lat", () => position.lat);
          utils.changeValue(state, "geojson.lng", () => position.lng);
        },
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "geojsonTemp.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "geojsonTemp.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormScrollError />

            <EditHeader
              title={t(post?.id ? "editItem.post" : "newItem.post")}
              subtitle={values?.title || ""}
              icon="CalendarDaysIcon"
              status={<Status status={values.status} />}
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  isChangingStatus={isUpdateFetching("updateStatus")}
                  onSubmit={handleSubmit}
                  onCancel={back}
                  // modo validate
                  onValidate={onValidate}
                  validateDisabled={
                    !canManagePost ||
                    !["pending_moderation"].includes(values.status)
                  }
                  // modo unpublish (= undo validate)
                  unpublishDisabled={
                    !canManagePost ||
                    !["pending_publication", "online"].includes(values.status)
                  }
                  onUnpublish={onUnpublish}
                  // do expirate
                  expirateDisabled={
                    !canManagePost || !["online"].includes(values.status)
                  }
                  onExpirate={onExpirate}
                  // undo expirate
                  unexpirateDisabled={
                    !canManagePost || !["offline"].includes(values.status)
                  }
                  onUnexpirate={onUnexpirate}
                />
              }
            />

            <div className="white-box mt-2">
              <div className="responsive-flex gap-2 md:gap-4">
                <div className="md:flex-[2]">
                  <FormControl
                    type="custom"
                    title={t("forms.site", {
                      wording: getWording(false, true),
                    })}
                    required
                    name="site"
                    containerStyle=""
                    isSelect
                    onChange={(value) => onSiteChange(value, form)}
                  >
                    <SiteSelect
                      checkRights={["canSubmitPost", "canManagePost"]}
                    />
                  </FormControl>
                </div>
                <div className="md:flex-[1]">
                  <FormControl
                    title={t("forms.startDatePublished")}
                    labelIcon="CalendarDaysIcon"
                    type="date"
                    name="publishedDate"
                    className="text-input"
                    required
                    editable={!canEditPublishedDate}
                  />
                </div>
              </div>
            </div>

            {/* depends of site */}

            <Transition show={Boolean(!siteId)} {...transitionAnimationProps}>
              <p className="text-center p-6 text-lg">
                {t("forms.pleaseSelectSite", {
                  wording: getWording(false, true),
                })}
              </p>
            </Transition>

            <Transition show={Boolean(siteId)} {...transitionAnimationProps}>
              <>
                <div className="white-box mt-2 gap-3">
                  <SectionTitle
                    icon="CalendarDaysIcon"
                    title={t("forms.postType")}
                  />
                  <FormControl
                    type="custom"
                    name="type"
                    inputContainerStyle="flex gap-3"
                    containerStyle="w-full"
                  >
                    <RadioBlock
                      Component={RadioBlockItem}
                      items={formatedPostTypes || []}
                      horizontal
                    />
                  </FormControl>

                  <div className="responsive-flex gap-4 mt-4">
                    <FormControl
                      title={t("forms.title")}
                      labelIcon="AiFillStar"
                      name="title"
                      required
                      containerStyle="w-full"
                    />
                  </div>
                </div>

                <Transition
                  show={
                    formatedPostTypes?.find(
                      (postType) => postType.id === values.type
                    )?.shouldHavePlanning ?? false
                  }
                  {...transitionAnimationProps}
                >
                  <div className="flex w-full flex-row gap-4 mb-6 mt-3">
                    <div
                      className={`${
                        values.type === 1 ? "md:w-1/3" : "w-full"
                      } transition-all`}
                    >
                      <div className="white-box mt-2">
                        <SectionTitle
                          title={t("forms.event")}
                          icon="TbCalendarEvent"
                        />
                        <div
                          className={`${
                            values.type === 1
                              ? "grid grid-rows-1 md:grid-rows-2"
                              : "grid grid-cols-1 md:grid-cols-2"
                          } gap-4 mt-3`}
                        >
                          <FormControl
                            title={t("forms.startDateEvent")}
                            labelIcon="CalendarDaysIcon"
                            type="date"
                            timeIntervals={5}
                            name="startEventDate"
                            className="p-4 w-full h-full text-sm border text-slate-200 rounded-lg border-slate-200"
                            required
                          />
                          <FormControl
                            title={t("forms.endDateEvent")}
                            labelIcon="CalendarDaysIcon"
                            type="date"
                            timeIntervals={5}
                            name="endEventDate"
                            className="p-4 w-full h-full text-sm border text-slate-200 rounded-lg border-slate-200"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <Transition
                      show={
                        formatedPostTypes?.find(
                          (postType) => postType.id === values.type
                        )?.shouldHavePerturbation ?? false
                      } // = alert
                      {...transitionAnimationProps}
                      className="w-full md:w-2/3"
                    >
                      <div className="white-box mt-2">
                        <SectionTitle
                          title={t("forms.perturbance")}
                          icon="BellAlertIcon"
                        />
                        <div className="flex w-full flex-col gap-4 mt-3">
                          <FormControl
                            title={t("forms.location")}
                            name="location"
                            labelIcon="IoLocationOutline"
                          />
                          <div className="grid-1or2 gap-4">
                            <FormControl
                              type="select"
                              title={t("forms.alertLevel")}
                              name="alertLevel"
                              labelIcon="TbGauge"
                              options={alertLevelList}
                            />
                            <FormControl
                              type="select"
                              title={t("forms.alertType")}
                              name="alertType"
                              labelIcon="Type"
                              options={alertTypeList}
                            />
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </Transition>

                <div className="responsive-flex gap-2 mt-2">
                  <div className="md:w-2/3">
                    <div className="flex flex-col gap-2 w-full">
                      {/* Image & desc */}
                      <div className="white-box">
                        <div className="responsive-flex gap-4">
                          <div className="flex flex-col w-full h-30 md:h-auto md:w-[180px]">
                            <FormControl
                              title={t("forms.image")}
                              labelIcon="PhotoIcon"
                              name="mainImage"
                              type="image"
                              maxWidth={imgSizes.postImage.width}
                              maxHeight={imgSizes.postImage.height}
                            />
                          </div>

                          <div className="flex w-full">
                            <FormControl
                              title={t("forms.description")}
                              type="richtext"
                              name="contentRich"
                              className="text-input"
                              containerStyle="w-full"
                              labelIcon="BsTextIndentLeft"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row gap-6 white-box mt-2">
                      <div className="flex flex-col gap-2 flex-1">
                        <SectionTitle
                          title={t("forms.diffusion")}
                          icon="BsPhoneVibrate"
                        />
                        <div className="flex flex-col gap-2">
                          {contactGroupsList?.length ? (
                            <FormControl
                              title={t("forms.recipients")}
                              type="custom"
                              name="contactGroups"
                              inputContainerStyle="mt-1"
                              containerStyle="w-full"
                              onChange={(value: any) =>
                                onContactChange(value, form)
                              }
                              required
                              validate={(value) => {
                                return value && value?.length > 0
                                  ? undefined
                                  : t("common.fieldRequired");
                              }}
                            >
                              <RadioBlock
                                Component={CheckBoxBlockItem}
                                items={contactGroupsList}
                                multiple
                                horizontal
                              />
                            </FormControl>
                          ) : (
                            <>
                              <SectionTitle title={t("forms.recipients")} />
                              <EmptyResult text={t("forms.noContactGroup")} />
                            </>
                          )}
                          {currentOrganization?.activeModuleSms && (
                            <>
                              <SectionTitle
                                title={t("forms.messageAndVocalSms")}
                              />
                              <FormControl type="custom" name="notifyBySMS">
                                <ToggleSwitch
                                  rightLabel={t("forms.informBySms")}
                                />
                              </FormControl>
                              <Transition
                                show={values.notifyBySMS ?? false}
                                {...transitionAnimationProps}
                              >
                                <FormControl
                                  type="textarea"
                                  name="smsContent"
                                  className="text-input"
                                />
                              </Transition>
                            </>
                          )}
                        </div>
                      </div>
                      <Transition
                        show={
                          (formatedPostTypes?.find(
                            (postType) => postType.id === values.type
                          )?.shouldHavePlanning &&
                            values.startEventDate !== undefined &&
                            values.startEventDate !== null) ??
                          false
                        }
                        className="flex flex-1"
                        {...transitionAnimationProps}
                      >
                        <div className="flex flex-col gap-4 mt-2 flex-1">
                          <SectionTitle
                            title={t("forms.notifications")}
                            icon="BellAlertIcon"
                          />
                          <span className="mt-[-10px] text-xs">
                            {t("forms.planNotifications")}
                          </span>
                          <div className="flex flex-col gap-2">
                            <FieldArray name="plannedNotifications">
                              {({ fields }: any) => (
                                <div>
                                  <SortableList
                                    items={fields}
                                    onMove={fields.move}
                                    render={PlannedNotificationItem}
                                  />

                                  <FormAddButton
                                    label={t("newItem.plannedNotification")}
                                    onClick={() =>
                                      fields.push({
                                        durationNumber: "5",
                                        durationType: "days",
                                        random: Math.floor(
                                          Math.random() * 10000
                                        ),
                                      })
                                    }
                                  />
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      </Transition>
                    </div>

                    {/* Extra fields & Map */}

                    <Transition
                      show={
                        (formatedPostTypes?.find(
                          (postType) => postType.id === values.type
                        )?.shouldHaveLocation ||
                          formatedPostTypes?.find(
                            (postType) => postType.id === values.type
                          )?.shouldHaveAdvice ||
                          formatedPostTypes?.find(
                            (postType) => postType.id === values.type
                          )?.shouldHaveAction) ??
                        false
                      }
                      {...transitionAnimationProps}
                    >
                      <div className="white-box mt-2">
                        <SectionTitle
                          title={t("forms.infos")}
                          icon="InformationCircleIcon"
                        />
                        <div className="flex w-full flex-col gap-4 mb-6 mt-3">
                          {/* Map */}

                          {/* position principale du marker + todo later: polygone autour (donc multipositions) */}

                          {enableMapField(
                            currentOrganization,
                            currentSite?.siteType?.isGeo
                          ) ? (
                            formatedPostTypes?.find(
                              (postType) => postType.id === values.type
                            )?.shouldHaveLocation ? (
                              <MapField
                                position={{
                                  lat: values.geojson.lat,
                                  lng: values.geojson.lng,
                                }}
                                positionTemp={{
                                  lat: values.geojsonTemp?.lat ?? 43.6043,
                                  lng: values.geojsonTemp?.lng ?? 1.4437,
                                }}
                                onChange={form.mutators.setPosition}
                                editable
                                mapPrefix="postForm"
                                form={form}
                              />
                            ) : null
                          ) : (
                            <div className="mt-2">
                              <p>
                                {t("forms.noModuleMap", {
                                  wording: getWording(true),
                                })}
                              </p>
                            </div>
                          )}

                          <div className="grid-1or2 w-full gap-4 mt-6">
                            <Transition
                              show={
                                formatedPostTypes?.find(
                                  (postType) => postType.id === values.type
                                )?.shouldHaveAction ?? false
                              }
                              {...transitionAnimationProps}
                            >
                              <FormControl
                                title={t("forms.actions")}
                                type="richtext"
                                name="actionRich"
                                className="text-input"
                              />
                            </Transition>
                            <Transition
                              show={
                                formatedPostTypes?.find(
                                  (postType) => postType.id === values.type
                                )?.shouldHaveAdvice ?? false
                              }
                              {...transitionAnimationProps}
                            >
                              <FormControl
                                title={t("forms.advices")}
                                type="richtext"
                                name="adviceRich"
                                className="text-input"
                              />
                            </Transition>
                          </div>
                        </div>
                      </div>
                    </Transition>

                    {post?.id && (
                      <div className="flex flex-col gap-1 white-box mt-2">
                        <SectionTitle
                          title={t("forms.qAndA")}
                          icon="QuestionMarkCircleIcon"
                        />
                        <div className="flex flex-col gap-1">
                          <FieldArray name="qAndAs">
                            {({ fields }: any) => (
                              <div>
                                <SortableList
                                  items={fields}
                                  onMove={fields.move}
                                  render={(rest) => QAndAItemForm(values, rest)}
                                />

                                <FormAddButton
                                  label={t("newItem.qAndA")}
                                  onClick={() =>
                                    fields.push({
                                      site: siteId,
                                      post: post?.id,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2 md:w-1/3">
                    <div className="flex flex-col gap-2 document-box">
                      <SectionTitle
                        title={t("forms.documents")}
                        icon="BsFiles"
                      />
                      <FormControl type="file" name="documents" />
                    </div>

                    {post?.id && (
                      <div className="flex flex-col gap-4 secondary-box">
                        <SectionTitle
                          title={t("forms.comments")}
                          icon="ChatBubbleLeftRightIcon"
                        />
                        <div className="flex flex-col gap-6">
                          <CommentsList
                            comments={values?.privateThread?.messages || []}
                            canComment={canComment}
                            postId={post?.id}
                            threadId={post?.privateThread?.id}
                            refresh={refresh}
                            // confirmRefresh={!pristine}
                            // thirdChoiceConfirmAction={() =>
                            //  onSubmitKeepPage(values)
                            // }
                            isPrivate
                            secondary
                            siteId={post.site.id}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </Transition>

            <HeaderButtonsEdit
              isChangingStatus={isUpdateFetching("updateStatus")}
              onSubmit={handleSubmit}
              onCancel={back}
              // modo validate
              onValidate={onValidate}
              validateDisabled={
                !canManagePost ||
                !["pending_moderation"].includes(values.status)
              }
              // modo unpublish (= undo validate)
              unpublishDisabled={
                !canManagePost ||
                !["pending_publication", "online"].includes(values.status)
              }
              onUnpublish={onUnpublish}
              // do expirate
              expirateDisabled={
                !canManagePost || !["online"].includes(values.status)
              }
              onExpirate={onExpirate}
              // undo expirate
              unexpirateDisabled={
                !canManagePost || !["offline"].includes(values.status)
              }
              onUnexpirate={onUnexpirate}
            />
          </form>
        );
      }}
    />
  );
}

export default PostForm;
