// import useTheme from 'hooks/useTheme';
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { I18nKey } from "i18n";
import { JText } from "common/components/Text/Text";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import Icon, { IconProps } from "components/Icon/Icon";
import Input, { InputProps } from "../FormInput/FormInput";
import { MentionTextInputProps } from "../MentionTextInput/MentionTextInput";

type Props = InputProps &
  MentionTextInputProps & {
    title?: string;
    titleKey?: I18nKey;
    subtitle?: string;
    subtitleKey?: I18nKey;
    helpText?: string;
    helpTextKey?: I18nKey;
    labelIcon?: IconProps["name"];
    IconComponent?: any;
    inline?: boolean;
    style?: object; // StyleProp<ViewStyle>;
    editable?: boolean;
    rightIconName?: string;
    name: string;
    required?: boolean;
    validate?: (value: any) => void | undefined | string;
    type?: string;
    onChange?: (value: any) => void;
    autoCompleteUrl?: string;
    containerStyle?: string;
    inputContainerStyle?: string;
    isSelect?: boolean;
    rows?: number;
    timeIntervals?: number;
  };

const keepEmptyValue = (value: any) => (value === "" ? null : value);

function FormControl({
  title,
  titleKey,
  // icon,
  subtitle,
  subtitleKey,
  helpText,
  helpTextKey,
  labelIcon,
  IconComponent,
  // leftIconColorName = "dark",
  // inline = false,
  // style,
  editable = true,
  name,
  validate,
  required = false,
  type = "text",
  onChange = () => {},
  containerStyle = "",
  inputContainerStyle = "",
  isSelect = false,
  rows,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const reqRule = (value: any) =>
    value !== "" && value != null ? undefined : t("common.fieldRequired");
  const checkboxReq = (value: any) =>
    value === true ? undefined : t("common.fieldRequired");

  let validator = !validate && required ? reqRule : validate;

  validator = type === "checkbox" && required ? checkboxReq : validator;

  const hasDropdown = type === "select" || isSelect;
  return (
    <Field
      validate={validator}
      required={required}
      parse={keepEmptyValue}
      name={name}
    >
      {({ input, meta }) => {
        const isError =
          meta &&
          (meta.touched || meta.submitFailed) &&
          (meta.error || meta.submitError);

        return (
          <div
            className={`flex flex-col ${containerStyle} ${
              isError ? "fieldError" : ""
            }`}
          >
            {(title || titleKey) && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <div className="field-title mb-1">
                {labelIcon && <Icon name={labelIcon} className="w-3 h-3" />}
                {IconComponent && <IconComponent className="w-3 h-3" />}
                <JText isBold label={title} labelKey={titleKey} />
                {required && <JText label="*" />}
              </div>
            )}
            {(subtitle || subtitleKey) && (
              <p className="mt-2 text-sm text-gray-500">
                <JText isItalic label={subtitle} labelKey={subtitleKey} />
              </p>
            )}
            <div className={`relative ${inputContainerStyle}`}>
              <Input
                {...input}
                {...rest}
                onChange={(val: any) => {
                  onChange(val);
                  input.onChange(val);
                }}
                type={type}
                disabled={!editable}
                error={isError}
                rows={rows}
              />
              {meta &&
                (meta.touched || meta.submitFailed) &&
                (meta.error || meta.submitError) && (
                  <div
                    className={`absolute inset-y-0 ${
                      hasDropdown ? "right-8" : "right-0"
                    } 
                  pr-3 flex items-center pointer-events-none h-[42px]`}
                  >
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
            </div>
            {(helpText || helpTextKey) && (
              <p className="mt-2 text-sm text-gray-500">
                <JText label={helpText} labelKey={helpTextKey} />
              </p>
            )}
            {meta &&
              (meta.touched || meta.submitFailed) &&
              (meta.error || meta.submitError) && (
                <p className="mt-1 text-sm text-red-600" id="email-error">
                  {meta.error || meta.submitError}
                </p>
              )}
          </div>
        );
      }}
    </Field>
  );
}

export default FormControl;
