// import Card from 'common/components/Card/Card';
import { useCallback, useMemo, useState, useEffect } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FieldArray } from "react-final-form-arrays";
import { BiMoveVertical } from "react-icons/bi";
import arrayMutators from "final-form-arrays";

import { Site } from "types";
import { ContactGroup } from "types/ContactGroup";

import EditHeader from "common/components/EditHeader/EditHeader";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import CheckBoxBlockItem from "common/components/CheckBoxBlockItem/CheckBoxBlockItem";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import Button from "common/components/Button/Button";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useList from "common/hooks/useList";
import useRouter from "common/hooks/use-router";
import SortableList from "common/components/SortableList/SortableList";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import useOrganization from "common/hooks/useOrganization";
import getWording from "common/utils/wording";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import SiteSelect from "components/select/SiteSelect/SiteSelect";

const initialFormState = {
  name: null,
  site: null,
  description: null,
  date: null,
  attendees: [],
  documents: [],
  contactGroups: [],
};

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

function AttendeeItem({ index, name, fields, dragProps }: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-end justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex w-full gap-3">
        <div className=" w-full  text-xs ">
          <FormControl
            name={`${name}.name`}
            titleKey={t("forms.name")}
            containerStyle="w-full"
            required
          />
        </div>
      </div>

      <div className="pr-4">
        <Button
          type="error-line"
          onClick={() => fields.remove(index)}
          icon="TrashIcon"
          className="px-3 py-3"
          compact
        />
      </div>

      <div
        className="absolute w-4 h-4 right-1 top-2 cursor-pointer hover:text-primary border-none"
        {...dragProps}
      >
        <DragHandle />
      </div>
    </div>
  );
}

function ActivityForm({ onSubmit, activity }: any) {
  const [siteId, setSiteId] = useState(activity?.site?.id);
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const { organization: currentOrganization } = useOrganization();
  const { t } = useTranslation();

  const { items: sites, setFilter: setFilterSite } = useList<Site>("sites", {
    populate: "contactGroups",
    defaultFilters: currentOrganization?.id
      ? {
          organization: currentOrganization.id,
          active: true,
          disabled: false,
        }
      : {
          active: true,
          disabled: false,
        },
    defaultSorts: ["siteType.id:ASC", "name:ASC"],
    cachePrefix: `ActivityForm_${currentOrganization?.id || "all"}`,
  });

  useEffect(() => {
    setFilterSite("organization", currentOrganization?.id || false);
  }, [currentOrganization, setFilterSite]);

  const back = useCallback(() => {
    if (!activity?.id) {
      router.navigate("/activities");
      return;
    }
    router.navigate(`/activities/${activity.id}`);
  }, [router, activity]);

  const onSiteChange = (value: any) => setSiteId(value);

  const contactGroupsList = useMemo(() => {
    if (!siteId) {
      return [];
    }

    const currentGroups = (sites || []).find(
      (item: Site) => item.id === siteId
    );

    if (!currentGroups || !currentGroups.contactGroups.length) {
      return [];
    }

    return currentGroups.contactGroups.map((item: any) => ({
      id: item.id,
      value: item.id,
      title: item.name,
    }));
  }, [sites, siteId]);

  // const { currentOrganization } = useOrganizations();
  const initialValues = useMemo(
    () =>
      activity?.id
        ? {
            ...activity,
            site: activity.site?.id,
            contactGroups: activity.contactGroups?.map(
              (group: ContactGroup) => group.id
            ),
            documents: activity.documents ? activity.documents : [],
          }
        : initialFormState,
    [activity /* currentOrganization */]
  );

  const onSubmitWrapper = async (values: any) => {
    const data = {
      ...values,
      attendees: values.attendees || [],
      contactGroups: values.contactGroups || [],
    };

    const res = await onSubmit(data);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <EditHeader
            title={t(activity?.id ? "editItem.activity" : "newItem.activity")}
            subtitle={values.name || ""}
            icon="CalendarDaysIcon"
            errorMessage={errorMessage}
            HeaderButtonsEdit={
              <HeaderButtonsEdit
                inHeader
                onSubmit={handleSubmit}
                onCancel={back}
              />
            }
          />
          <div className="white-box mt-2">
            <div className="responsive-flex gap-3">
              <div className="md:w-2/3">
                <FormControl
                  title={t("forms.name")}
                  labelIcon="AiFillStar"
                  name="name"
                  required
                />
              </div>

              <div className="flex flex-col md:w-1/3">
                <FormControl
                  type="custom"
                  title={t("forms.site", {
                    wording: getWording(false, true),
                  })}
                  required
                  name="site"
                  containerStyle=""
                  isSelect
                  onChange={onSiteChange}
                >
                  <SiteSelect checkRights={["canManageActivity"]} />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="responsive-flex gap-2 mt-2">
            <div className="md:w-2/3">
              <div className="flex flex-col gap-2 w-full">
                {/* desc */}
                <div className="white-box">
                  <div className="flex w-full">
                    <FormControl
                      title={t("forms.description")}
                      type="textarea"
                      name="description"
                      className="text-input"
                      inputContainerStyle="flex flex-1"
                      containerStyle="w-full"
                      labelIcon="BsTextIndentLeft"
                    />
                  </div>

                  <div className="grid-1or2 mt-4 gap-4">
                    <FormControl
                      title={t("forms.date")}
                      labelIcon="CalendarDaysIcon"
                      type="date"
                      name="date"
                      className="text-input"
                    />
                  </div>
                </div>

                <div className="document-box">
                  <SectionTitle
                    title={t("forms.documents")}
                    icon="BsFiles"
                    className="mb-2"
                  />
                  <FormControl type="file" name="documents" />
                </div>
              </div>
            </div>

            <div className="md:w-1/3">
              <div className="flex flex-col gap-2 w-full">
                <div className="white-box">
                  {contactGroupsList?.length ? (
                    <FormControl
                      title={t("forms.recipients")}
                      type="custom"
                      name="contactGroups"
                      inputContainerStyle="grid grid-cols-1 gap-3"
                      containerStyle="w-full"
                    >
                      <RadioBlock
                        Component={CheckBoxBlockItem}
                        items={contactGroupsList}
                        multiple
                      />
                    </FormControl>
                  ) : (
                    <>
                      <SectionTitle title={t("forms.recipients")} />
                      <span className="text-slate-500 italic text-sm">
                        {t("forms.noContactGroup")}
                      </span>
                    </>
                  )}
                </div>
                <div className="white-box">
                  <SectionTitle
                    title={t("forms.attendees")}
                    // icon="BsFiles"
                    className="mb-2"
                  />
                  <FieldArray name="attendees">
                    {({ fields }: any) => (
                      <div>
                        <SortableList
                          items={fields}
                          onMove={fields.move}
                          render={AttendeeItem}
                        />

                        <FormAddButton
                          label={t("newItem.attendee")}
                          onClick={() =>
                            fields.push({
                              name: "",
                            })
                          }
                        />
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>
          </div>

          <HeaderButtonsEdit onSubmit={handleSubmit} onCancel={back} />
        </form>
      )}
    />
  );
}

export default ActivityForm;
