import { useCallback } from "react";
import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Site } from "types";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";

export type ProcessOptionsType = (options: Site[]) => {
  value: string;
  label: string;
}[];

function Label({ parent, name, logo, mainImage }: any) {
  return (
    <div className="flex gap-2 items-center">
      {!parent && (
        <div className="rounded-lg overflow-hidden w-8 h-8">
          <ImageComponent
            image={
              logo || mainImage
                ? ImageFromStrapiMedia(logo ?? mainImage)?.uri
                : undefined
            }
          />
        </div>
      )}

      <span className={`${parent ? "pl-2" : ""}`}>
        {parent ? `- ${name}` : name}
      </span>
    </div>
  );
}

const formatOptionLabel = ({ label, logo, mainImage, parent }: any) => (
  <Label name={label} logo={logo} mainImage={mainImage} parent={parent} />
);

const sortAndIntercalateOptions = (options: any[]) => {
  // Trier les options par ordre alphabétique du champ "name"
  options.sort((a: any, b: any) => a.name.localeCompare(b.name));

  // Création de la structure de regroupement par parent
  const groupedOptions = options.reduce((acc, option) => {
    if (!acc[option.parent?.id]) {
      acc[option.parent?.id] = [];
    }
    acc[option.parent?.id].push(option);
    return acc;
  }, {});

  // Fonction récursive pour organiser et intercaler les options
  const intercalateOptions: any = (parentId: any) => {
    const result = [];
    const grouped = groupedOptions[parentId] || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const option of grouped) {
      result.push(option);
      result.push(...intercalateOptions(option.id));
    }
    return result;
  };

  return intercalateOptions(undefined);
};

function SiteSelect(props: any) {
  const { user: currentUser } = useAuth();
  const { organization } = useOrganization();

  const {
    checkRights = ["canSubmitPost", "canManagePost", "canManageActivity"],
    allowReadOnlyOptions = false,
    filterOnParentType,
    filterOnParents = false,
    filterOnActive,
  } = props;

  const processOptions = useCallback(
    (options: Site[]) => {
      const siteUsers = currentUser?.mySitesId;

      /* sites tree */
      let preOptions = sortAndIntercalateOptions(options);

      preOptions = preOptions.reduce(
        (acc: any, { id, name, logo, mainImage, parent }: any) => {
          if (
            siteUsers?.includes(id) ||
            currentUser?.isSuperadmin ||
            currentUser?.isAdmin
          ) {
            acc.push({
              value: id,
              label: name,
              logo,
              mainImage,
              parent,
            });
          }

          return acc;
        },
        []
      );

      if (!allowReadOnlyOptions) {
        preOptions = preOptions.filter((siteOption: any) => {
          let rightFound = false;

          checkRights?.forEach((rightName: string) => {
            if (
              currentUser?.checkMyRight(siteOption?.value, rightName, [
                "isAdmin",
              ])
            ) {
              rightFound = true;
            }
          });

          return rightFound;
        });
      }

      return preOptions;
    },
    [currentUser, allowReadOnlyOptions, checkRights]
  );

  const filters = [
    { name: "organization", value: organization?.id },
    { name: "disabled", value: false },
  ];

  if (filterOnParentType) {
    filters.push({
      name: "siteType",
      value: filterOnParentType?.id || filterOnParentType,
    });
    filters.push({ name: "siteType][shouldSelectParent", value: false });
  } else if (filterOnParents) {
    filters.push({ name: "siteType][shouldSelectParent", value: false });
    if (filterOnActive) {
      filters.push({ name: "active", value: true });
    }
  } else {
    filters.push({ name: "active", value: true });
  }

  return (
    <RemoteSelect
      url="sites"
      processOptions={processOptions}
      filters={filters}
      alsoOnChange={props.alsoOnChange}
      populate={["parent", "logo", "mainImage"]}
      formatOptionLabel={formatOptionLabel}
      {...props}
    />
  );
}

export default SiteSelect;
