import { useCallback, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BsCardChecklist, BsFiles } from "react-icons/bs";
import {
  CalendarDaysIcon,
  ClockIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Site, SiteUser as SiteUserType } from "types";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import PageLoading from "common/components/PageLoading/PageLoading";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import MapField from "common/components/MapField/MapField";
import useAuth from "common/hooks/useAuth";
import useOrganization from "common/hooks/useOrganization";
import SiteUser from "common/components/SiteUser/SiteUser";
import DetailHeader from "common/components/DetailHeader/DetailHeader";
import HeaderButtons from "common/components/DetailHeader/HeaderButtons";
import StepsList from "common/components/StepsList/StepsList";
import DefaultFilesList from "common/components/FileUploader/DefaultFilesList";
import EmptyResult from "common/components/EmptyResult/EmptyResult";
import checkRights from "common/utils/checkRights";
import Status from "common/components/Status/Status";
import SiteTypeIcon from "common/components/PostsAndActivities/SiteTypeIcon";
import AlertsContext from "common/providers/alerts";
import enableMapField from "common/utils/enableMapField";
import QAndA from "common/components/QAndA/QAndA";
import { QAndA as QAndAType } from "types/QAndA";
import ReactQuill from "react-quill";

interface INumberItem {
  item: any;
}

function NumberItem({ item }: INumberItem) {
  const { label, value } = item;
  return (
    <div className="flex flex-col gap-1 items-center border border-primary/60 rounded-lg py-3 px-6">
      <div className="text-xs leading-3 text-primary uppercase">{label}</div>
      <div className="font-bold text-2xl leading-5 text-ternary">{value}</div>
    </div>
  );
}

function LinkItem({ item }: INumberItem) {
  const { label, target } = item;
  return (
    <a
      href={target}
      target="_blank"
      rel="noreferrer"
      className="flex flex-col gap-1 items-center border border-primary/60 rounded-lg py-3 px-6 text-sm leading-3 text-primary"
    >
      {label}
    </a>
  );
}

function SiteDetails() {
  const router = useRouter();
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;
  const { item, isFetching, removeItem, error } = useItem<Site>("sites", id, {
    populate: [
      "mainImage",
      "logo",
      "steps",
      "keyIndicators",
      "siteUsers",
      "siteUsers.contact",
      "siteUsers.user",
      "siteUsers.user.avatar",
      "siteUsers.jobPosition",
      "documents",
      "siteType",
      "qAndAs",
      "qAndAs.post",
      "links",
      "parent",
      "adminContact",
    ],
  });

  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const canEditSite = checkRights(
    currentUser,
    "canEditSite",
    item ? [item?.id] : undefined,
    ["isAdmin"]
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.deleted"), "success");
      router.navigate("/sites");
    }
  }, [setAlert, router, error, t]);

  const onDelete = useCallback(async () => {
    try {
      if (currentUser?.isSuperadmin) await removeItem(id);
    } catch (e) {
      setAlert(e, "danger");
      console.error(e);
    }

    back();
  }, [back, currentUser?.isSuperadmin, removeItem, id, setAlert]);

  const contents = item?.description?.split("\n");

  return isFetching || !item ? (
    <PageLoading error={error} />
  ) : (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
      <DetailHeader
        backLink="/sites"
        title={item.name}
        isDraft={item.isDraft}
        Status={
          !currentOrganization?.isMonoActivity ? (
            <div className="flex gap-2 flex-wrap">
              {!currentOrganization?.isMonoActivity && !!item.siteType && (
                <Status
                  status={item.siteType?.name}
                  label={item.parent ? item.parent.name : undefined}
                  color={item.siteType.color}
                />
              )}
              {item.isDraft && (
                <div
                  className="px-2 rounded-md bg-slate-100 text-secondary border border-secondary-500 flex flex-col justify-center items-center text-sm w-fit"
                  title={t("forms.adminOption")}
                >
                  {t("forms.inDraft")}
                </div>
              )}
            </div>
          ) : undefined
        }
        qrCode={`${import.meta.env.VITE_FRONT_URL}/sites/site/${id}/info`}
        TitleIcon={
          <SiteTypeIcon
            labelType={item?.siteType?.name}
            iconStyle="w-7 h-7 text-white"
          />
        }
        HeaderInfos={
          item.siteType?.shouldHaveDates ? (
            <div className="flex gap-2 w-full">
              {item.siteType?.shouldHaveDates &&
                (item.startDate || item.endDate) && (
                  <div className="flex flex-col">
                    <div className="flex items-center text-xs gap-1 mb-2 md:mb-0 field-title">
                      <CalendarDaysIcon className="w-4 h-4" />
                      {t("site.dates")}
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="responsive-flex items-center gap-3">
                        {item.startDate && (
                          <div className="flex items-center">
                            <ClockIcon className="w-4 h-4 mr-1" />
                            <span className="font-bold">
                              {!item.endDate && `A partir du `}
                              {dayjs(item.startDate).format("LLL")}
                            </span>
                          </div>
                        )}
                        {item.endDate && (
                          <div className="flex items-center">
                            <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                            <span className="font-bold">
                              {!item.startDate && `Jusqu'au `}
                              {dayjs(item.endDate).format("LLL")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ) : undefined
        }
        subtitleIcon={item.city || item.location ? "MapPinIcon" : undefined}
        SubTitle={
          item.city || item.location ? (
            <>
              <span className="font-bold">{item.location}</span>
              {item.city && <span> | {item.city}</span>}
            </>
          ) : undefined
        }
        HeaderButtons={
          <HeaderButtons
            editUrl={`/sites/edit/${id}`}
            editDisabled={!canEditSite}
            onDelete={
              canEditSite && currentUser?.isSuperadmin ? onDelete : undefined
            }
            deleteDisabled={!canEditSite}
          />
        }
      />

      <div className="responsive-flex gap-2 mt-2">
        <div className="md:w-2/3">
          <div className="flex flex-col gap-2 w-full">
            {/* Image & desc */}
            <div className="responsive-flex white-box relative">
              <div
                className="rounded-lg overflow-hidden h-24 w-24 border-4 border-white
              absolute top-6 left-16"
              >
                <ImageComponent
                  image={
                    item.logo ? ImageFromStrapiMedia(item.logo)?.uri : undefined
                  }
                />
              </div>

              <div className="responsive-flex-lg gap-8 w-full">
                <div
                  className="w-full rounded-lg overflow-hidden 
                md:flex-[1] h-[250px] md:h-[300px] mt-12 border border-black/10"
                >
                  <ImageComponent
                    image={
                      item.mainImage
                        ? ImageFromStrapiMedia(item.mainImage)?.uri
                        : undefined
                    }
                  />
                </div>
                <div className="md:flex-[1] py-4">
                  {item.descriptionRich ? (
                    <div className="richtext-rendered">
                      <ReactQuill
                        theme="snow"
                        readOnly
                        value={item.descriptionRich}
                      />
                    </div>
                  ) : item.description ? (
                    contents?.map((p: any, index: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <p key={`p-${index}`} className="mb-4">
                        {p}
                      </p>
                    ))
                  ) : (
                    <p>{t("forms.noContent")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Extra fields & Map */}
          {enableMapField(currentOrganization, item.siteType?.isGeo) && (
            <div className="flex white-box mt-2">
              {/* position principale du marker + todo later: polygone autour (donc multipositions) */}
              <MapField
                mapPrefix="siteDetails"
                position={{
                  lat: item?.geojson?.lat,
                  lng: item?.geojson?.lng,
                }}
              />
            </div>
          )}

          <div className="white-box mt-2">
            <div className="flex text-xs mb-4 gap-1 field-title">
              <BsCardChecklist className="w-4 h-4" />
              {t("forms.links")}
            </div>

            {item?.links.length > 0 ? (
              <div className="grid-1or2or3 gap-4">
                {(item?.links || []).map((link: any) => (
                  <LinkItem item={link} />
                ))}
              </div>
            ) : (
              <EmptyResult text={t("common.noLinks")} />
            )}
          </div>

          <div className="white-box mt-2">
            <div className="flex text-xs mb-4 gap-1 field-title">
              <BsCardChecklist className="w-4 h-4" />
              {t("forms.keyIndicators")}
            </div>

            {item?.keyIndicators.length > 0 ? (
              <div className="grid-1or2or3 gap-4">
                {(item?.keyIndicators || []).map((ind: any) => (
                  <NumberItem item={ind} />
                ))}
              </div>
            ) : (
              <EmptyResult text={t("common.noKeyIndicators")} />
            )}
          </div>

          <div className="white-box mt-2">
            <div>
              <div className="flex items-center text-xs mb-4 gap-1 field-title">
                <UserGroupIcon className="w-4 h-4" />
                {t("forms.users")}
              </div>

              {item.siteUsers?.length > 0 ? (
                <div className="grid-1or2 gap-4">
                  {item.siteUsers?.map((u: SiteUserType) => (
                    <div className="grid grid-cols-1 gap-3">
                      <SiteUser item={u} />
                    </div>
                  ))}
                </div>
              ) : (
                <EmptyResult text={t("common.noSiteUser")} />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/3 gap-2">
          <div className="secondary-box">
            <div className="flex items-center text-xs mb-4 gap-1 field-title">
              <UserCircleIcon className="w-3 h-3" />
              {t("forms.contactLinked")}
            </div>

            {item?.adminContact?.name ?? item?.adminContact?.username ?? (
              <EmptyResult text={t("common.noContact")} />
            )}
          </div>
          <div className="document-box">
            <div className="flex items-center text-xs mb-4 gap-1 field-title">
              <BsFiles className="w-3 h-3" />
              {t("forms.documents")}
            </div>

            <DefaultFilesList items={item?.documents || []} />
          </div>
          <div className="tertiary-box gap-1 w-full">
            <div className="flex text-xs mb-4 gap-1 field-title items-center">
              <CheckCircleIcon className="w-4 h-4" />
              {t("forms.steps")}
            </div>

            {item?.steps?.length > 0 ? (
              <StepsList steps={item?.steps} />
            ) : (
              <EmptyResult text={t("common.noSteps")} />
            )}
          </div>

          <div className="white-box mt-2">
            <div>
              <div className="responsive-flex items-center text-xs mb-4 gap-1 field-title">
                <QuestionMarkCircleIcon className="w-4 h-4" />
                {t("forms.qAndA")}
              </div>

              {item?.qAndAs?.length > 0 ? (
                item.qAndAs?.map((q: QAndAType) => <QAndA item={q} />)
              ) : (
                <EmptyResult text={t("common.noQAndA")} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteDetails;
