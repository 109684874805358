import Login from "pages/Auth/Login";
import Page404 from "pages/Page404";
import Home from "pages/Home/Home";
import Organizations from "pages/Organizations/Organizations";
import Organization from "pages/Organizations/Organization";
import Users from "pages/Users/Users";
import User from "pages/Users/User";
import Contacts from "pages/Contacts/Contacts";
import Contact from "pages/Contacts/Contact";
import JobPositions from "pages/JobPositions/JobPositions";
import JobPosition from "pages/JobPositions/JobPosition";
import ContactGroups from "pages/ContactGroups/ContactGroups";
import ContactGroup from "pages/ContactGroups/ContactGroup";
import SiteDetails from "pages/Site/SiteDetails";
import Site from "pages/Site/Site";
import Sites from "pages/Site/Sites";
import MyAccount from "pages/Users/MyAccount";
import Support from "pages/Support/Support";
import Posts from "pages/Posts/Posts";
import Post from "pages/Posts/Post";
import PostDetails from "pages/Posts/PostDetails";
import Activities from "pages/Activities/Activities";
import ActivityDetails from "pages/Activities/ActivityDetails";
import Activity from "pages/Activities/Activity";
import Threads from "pages/Threads/Threads";
import Thread from "pages/Threads/Thread";
import ContactGroupDetails from "pages/ContactGroups/ContactGroupDetails";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import AlertTypes from "pages/AlertTypes/AlertTypes";
import AlertType from "pages/AlertTypes/AlertType";
import ThreadTypes from "pages/ThreadTypes/ThreadTypes";
import ThreadType from "pages/ThreadTypes/ThreadType";
import PostTypes from "pages/PostTypes/PostTypes";
import PostType from "pages/PostTypes/PostType";
import UserTypes from "pages/UserTypes/UserTypes";
import UserType from "pages/UserTypes/UserType";
import Tags from "pages/Tags/Tags";
import Tag from "pages/Tags/Tag";
import Markers from "pages/Markers/Markers";
import Marker from "pages/Markers/Marker";
import Imports from "pages/Imports/Imports";

const managers = ["SuperAdmin", "Administrateur"];
const pros = ["SuperAdmin", "Administrateur", "Pro"];

const routes = [
  // publications
  { path: "/posts", roles: pros, Component: Posts },
  { path: "/posts/:id", roles: pros, Component: PostDetails },
  { path: "/posts/add", roles: pros, Component: Post },
  { path: "/posts/edit/:id", roles: pros, Component: Post },
  { path: "/posts/duplicate/:id", roles: pros, Component: Post },
  //  chantiers
  { path: "/sites", roles: pros, Component: Sites },
  { path: "/sites/:id", roles: pros, Component: SiteDetails },
  { path: "/sites/add", roles: pros, Component: Site },
  { path: "/sites/edit/:id", roles: pros, Component: Site },
  //  echanges
  { path: "/threads", roles: pros, Component: Threads },
  { path: "/threads/:id", roles: pros, Component: Thread },
  // others
  { path: "/activities", roles: pros, Component: Activities },
  { path: "/activities/:id", roles: pros, Component: ActivityDetails },
  { path: "/activities/add", roles: pros, Component: Activity },
  { path: "/activities/edit/:id", roles: pros, Component: Activity },
  { path: "/users", roles: managers, Component: Users },
  { path: "/users/add", roles: managers, Component: User },
  { path: "/users/edit/:id", roles: managers, Component: User },
  { path: "/my-account", roles: pros, Component: MyAccount },
  { path: "/contacts", roles: pros, Component: Contacts },
  { path: "/contacts/add", roles: pros, Component: Contact },
  { path: "/contacts/edit/:id", roles: pros, Component: Contact },
  { path: "/contact-groups", roles: pros, Component: ContactGroups },
  { path: "/contact-groups/:id", roles: pros, Component: ContactGroupDetails },
  { path: "/contact-groups/add", roles: pros, Component: ContactGroup },
  { path: "/contact-groups/edit/:id", roles: pros, Component: ContactGroup },
  { path: "/job-positions", roles: pros, Component: JobPositions },
  { path: "/job-positions/add", roles: pros, Component: JobPosition },
  { path: "/job-positions/edit/:id", roles: pros, Component: JobPosition },
  { path: "/organizations", roles: ["SuperAdmin"], Component: Organizations },
  {
    path: "/organizations/add",
    roles: ["SuperAdmin"],
    Component: Organization,
  },
  {
    path: "/organizations/edit/:id",
    roles: ["SuperAdmin"],
    Component: Organization,
  },
  {
    path: "my-organization",
    roles: ["SuperAdmin", "Administrateur"],
    Component: Organization,
  },
  { path: "/sites", roles: pros, Component: Sites },
  { path: "/sites/:id", roles: pros, Component: SiteDetails },
  { path: "/sites/add", roles: pros, Component: Site },
  { path: "/sites/edit/:id", roles: pros, Component: Site },
  { path: "/support", roles: "all", Component: Support },
  { path: "/alert-types", roles: pros, Component: AlertTypes },
  { path: "/alert-types/add", roles: pros, Component: AlertType },
  { path: "/alert-types/edit/:id", roles: pros, Component: AlertType },
  { path: "/user-types", roles: pros, Component: UserTypes },
  { path: "/user-types/add", roles: pros, Component: UserType },
  { path: "/user-types/edit/:id", roles: pros, Component: UserType },
  { path: "/markers", roles: pros, Component: Markers },
  { path: "/markers/add", roles: pros, Component: Marker },
  { path: "/markers/edit/:id", roles: pros, Component: Marker },
  { path: "/tags", roles: pros, Component: Tags },
  { path: "/tags/add", roles: pros, Component: Tag },
  { path: "/tags/edit/:id", roles: pros, Component: Tag },
  { path: "/thread-types", roles: ["SuperAdmin"], Component: ThreadTypes },
  { path: "/thread-types/add", roles: ["SuperAdmin"], Component: ThreadType },
  {
    path: "/thread-types/edit/:id",
    roles: ["SuperAdmin"],
    Component: ThreadType,
  },
  { path: "/post-types", roles: ["SuperAdmin"], Component: PostTypes },
  { path: "/post-types/edit/:id", roles: ["SuperAdmin"], Component: PostType },
  { path: "/imports", roles: ["SuperAdmin"], Component: Imports },
  { path: "/", roles: "all", Component: Home },
  { path: "*", roles: "all", exact: false, Component: Page404 },
];

export const loginRoutes = [
  { path: "/login", roles: "all", Component: Login },
  { path: "/forgot-password", roles: "all", Component: ForgotPassword },
  { path: "/reset-password", roles: "all", Component: ResetPassword },
  { path: "/", roles: "all", Component: Login },
  { path: "*", roles: "all", exact: false, Component: Login },
];

export default routes;
