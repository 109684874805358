import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  HomeIcon,
  PhoneIcon,
  UserCircleIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  WalletIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { AiOutlineMonitor } from "react-icons/ai";

import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import SupportIcon from "components/Icon/icons/SupportIcon";
import { IconNames } from "components/Icon/Icon";
import { TbBellCog, TbFileImport } from "react-icons/tb";
import { BiCommentError, BiCalendarExclamation } from "react-icons/bi";

export type IMenuChild = {
  id: string;
  label: string;
  Icon: any;
  disableBoard?: boolean;
  add?: boolean;
  addRightType?: string;
  addRightKey?: string;
  addRightKeyJoker?: string[];
  useWordingPlural?: boolean;
};
export type IMenuGroup = {
  label: string;
  children: IMenuChild[];
  icon: IconNames;
  useWordingPlural?: boolean;
};

export type IMenuConf = Record<string, IMenuGroup>;

/** ******************************** Sections ******************************* */

const managementSection: IMenuConf = {
  // suivi
  suivi: {
    label: "monitoring",
    icon: "MdOutlineChecklist",
    children: [
      {
        id: "./",
        label: "board",
        Icon: HomeIcon,
        disableBoard: true,
      },
      {
        id: "posts",
        label: "posts",
        Icon: CalendarDaysIcon,
        add: true,
        addRightType: "jobPosition",
        addRightKey: "canSubmitPost",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
      {
        id: "threads",
        label: "threads",
        Icon: ChatBubbleLeftRightIcon,
      },
      {
        id: "activities",
        label: "activities",
        Icon: AiOutlineMonitor,
        add: true,
        addRightType: "jobPosition",
        addRightKey: "canManageActivity",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
    ],
  },
  diffusion: {
    label: "diffusion",
    icon: "BsPhoneVibrate",
    children: [
      {
        id: "contacts",
        label: "contacts",
        Icon: PhoneIcon,
        add: true,
        addRightType: "jobPosition",
        addRightKey: "canManageContact",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
      {
        id: "contact-groups",
        label: "contactGroups",
        Icon: UserGroupIcon,
        add: true,
        addRightType: "jobPosition",
        addRightKey: "canManageContact",
        addRightKeyJoker: ["isAdmin", "isSuperadmin"],
      },
    ],
  },
};

const organizationsSection: IMenuChild[] = [
  {
    id: "organizations",
    label: "organizations",
    Icon: BuildingOfficeIcon,
  },
];

const mySpaceSection: IMenuChild[] = [
  {
    id: "my-account",
    label: "myAccount",
    Icon: UserIcon,
  },
  {
    id: "support",
    label: "support",
    Icon: SupportIcon,
  },
];

const manageSection = [
  // gestion
  {
    id: "sites",
    label: "sites",
    useWordingPlural: true,
    Icon: WorkSiteIcon,
    add: true,
    addRightType: "role",
    addRightKey: "isAdmin",
  },
  {
    id: "job-positions",
    label: "jobPositions",
    Icon: WalletIcon,
    add: true,
    addRightType: "role",
    addRightKey: "isAdmin",
  },
];

const typesSection = [
  {
    id: "alert-types",
    label: "alertTypes",
    Icon: TbBellCog,
    add: true,
    addRightType: "role",
    addRightKey: "isSuperAdmin",
  },
  {
    id: "thread-types",
    label: "threadTypes",
    Icon: BiCommentError,
    add: true,
    addRightType: "role",
    addRightKey: "isSuperAdmin",
  },
  {
    id: "post-types",
    label: "postTypes",
    Icon: BiCalendarExclamation,
    add: true,
    addRightType: "role",
    addRightKey: "isSuperAdmin",
  },
];

const usersSection = {
  id: "users",
  label: "users",
  Icon: UsersIcon,
  add: true,
  addRightType: "role",
  addRightKey: "isAdmin",
};

const userTypesSection = {
  id: "user-types",
  label: "userTypes",
  Icon: UserCircleIcon,
  add: true,
};

const tagsSection = {
  id: "tags",
  label: "tags",
  Icon: TagIcon,
  add: true,
};

const myOrganizationSection = {
  id: "my-organization",
  label: "myOrganization",
  Icon: BuildingOfficeIcon,
};

const adminUsersSection: IMenuChild[] = [
  {
    id: "markers",
    label: "markers",
    Icon: MapPinIcon,
    add: true,
    addRightType: "role",
    addRightKey: "isAdmin",
  },
  {
    id: "users",
    label: "publicAccounts",
    Icon: UsersIcon,
    add: false,
  },
];

const superadminImportSection: IMenuChild[] = [
  {
    id: "imports",
    label: "import",
    Icon: TbFileImport,
  },
];

/** **********************************  Menus by roles ************************** */

export const proMenu: IMenuConf = {
  ...managementSection,
  gestion: {
    label: "manage",
    icon: "GiMineTruck",
    children: [...manageSection],
  },
  reglages: {
    label: "params",
    icon: "VcsSettings",
    children: [...mySpaceSection],
  },
};

export const superadminRootMenu: IMenuConf = {
  reglages: {
    label: "params",
    icon: "VcsSettings",
    children: [
      ...mySpaceSection,
      ...organizationsSection,
      ...adminUsersSection,
      ...typesSection,
      userTypesSection,
    ],
  },
  import: {
    label: "import",
    icon: "TbFileImport",
    children: [...superadminImportSection],
  },
};
export const superadminFilteredMenu: IMenuConf = {
  ...managementSection,
  gestion: {
    label: "manage",
    icon: "GiMineTruck",
    children: [...manageSection, tagsSection, usersSection],
  },
  reglages: {
    label: "params",
    icon: "VcsSettings",
    children: [...mySpaceSection],
  },
};

if (import.meta.env.VITE_TEMPLATE !== "parlonstravaux") {
  superadminFilteredMenu.gestion.children.push(myOrganizationSection);
}
